import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, TemplateRef } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormArray,
  ReactiveFormsModule,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { toInteger } from "lodash-es";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ToastrService } from "ngx-toastr";
import { map, Observable, startWith } from "rxjs";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { PublicFooterComponent } from "src/app/component/public-footer/public-footer.component";
import { TAPIListResult } from "src/app/contract/api.contract";
import { RolesEnum } from "src/app/contract/user.contract";
import { RefAlliance } from "src/app/model/ref/ref-alliance.model";
import { UserRole } from "src/app/model/user/user-role.model";
import { User, UserCreateOrUpdate } from "src/app/model/user/user.model";
import { AllianceService } from "src/app/service/alliance.service";
import { RoleService } from "src/app/service/role.service";
import { passWordUpdate, UserService } from "src/app/service/user.service";
import { logFormErrors } from "src/app/util/form.util";
import { logger } from "src/app/util/logger.util";
import { passwordValidator } from "src/app/util/validator.util";
import { UserRoleComponent } from "../user-role/user-role.component";

@Component({
  selector: "app-user-edit",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    CommonModule,
    ReactiveFormsModule,
    UserRoleComponent,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatIcon,
    MatButtonModule,
    PublicFooterComponent,
    MatTooltipModule,
  ],
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {
  private userService = inject(UserService);
  private allianceService = inject(AllianceService);
  private roleService = inject(RoleService);
  private route = inject(ActivatedRoute);
  private formBuilder = inject(FormBuilder);
  protected modalService = inject(NgbModal);
  private router = inject(Router);
  private toastrService = inject(ToastrService);

  public formInvalid: boolean = false;
  searchControl = new FormControl();

  protected passwordForm = this.formBuilder.group(
    {
      oldPassword: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      newPassword: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, passwordValidator],
      }),
      cnfPassword: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      validator: this.passwordMatchValidator,
    },
  );

  public userForm = this.formBuilder.group({
    name: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    lastName: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    role: this.formBuilder.array([]),
    email: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    mobile: new FormControl(""),
    organisationName: new FormControl(""),
    allianceId: this.formBuilder.control([] as string[] | null),
  });

  public user: User = new User();
  public allianceListing: TAPIListResult<RefAlliance> = { count: 0, rows: [] };
  public rolesListing: TAPIListResult<UserRole> = { count: 0, rows: [] };

  public userRoles: { id: number; name: string }[] = [];
  public isEditingRoles = false;
  public userId: string | null = null;
  public showReset: boolean = false;
  /**
   * @description Angular lifecycle hook called after component initialization.
   */
  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get("id");
    this.initializeData().finally(() => {
      this.setValidation();

      // this.userForm.valueChanges.subscribe(() => {
      // this.setValidation();
      // });
    });
  }

  /*
   * Enables/Disables certain required fields for admin user editing
   */
  private setValidation() {
    const roleData = (this.userForm.value.role || []) as { id?: number }[];

    const isAdmin =
      roleData.length === 1 && "id" in roleData[0] && roleData[0].id === RolesEnum.Admin;

    const toggleFields = ["allianceId", "organisationName"] as const;

    if (isAdmin) {
      toggleFields.forEach((field) => {
        this.userForm.get(field)?.clearValidators();
        this.userForm.get(field)?.disable();
        this.userForm.get(field)?.updateValueAndValidity({ onlySelf: true });
      });

      return;
    }

    toggleFields.forEach((field) => {
      this.userForm.get(field)?.setValidators([Validators.required]);
      this.userForm.get(field)?.enable();
      this.userForm.get(field)?.updateValueAndValidity({ onlySelf: true });
    });
  }

  /**
   * @description Initializes data by loading user details and other necessary data.
   */
  private async initializeData(): Promise<void> {
    if (this.userId) {
      await this.loadUserDetails(this.userId);
      this.showReset = true;
    }

    await Promise.all([this.loadAlliances(), this.loadUserRoles()]);
  }

  /**
   * @description Loads user details by ID and populates the form.
   * @param id The ID of the user to load.
   */
  private async loadUserDetails(id: string): Promise<void> {
    try {
      this.user = await this.userService.fetchSingleUser(id);
      this.preFillForm();
    } catch (error) {
      logger.error("Error loading user details", error);
    }
  }

  /**
   * @description Loads the list of alliances.
   */
  private async loadAlliances(): Promise<void> {
    try {
      this.allianceListing = await this.allianceService.fetchAlliance({
        sortBy: "name",
        order: "ASC",
      });
    } catch (error) {
      logger.error("Error loading alliances", error);
    }
  }

  /**
   * @description Loads the list of user roles.
   */
  private async loadUserRoles(): Promise<void> {
    try {
      this.rolesListing = await this.roleService.fetchUserRole({});
    } catch (error) {
      logger.error("Error loading user roles", error);
    }
  }

  /**
   * @description Prefills the form with user details.
   */
  private preFillForm(): void {
    const { firstName, lastName, email, organisationName, alliance, phone, roles } = this.user;

    const allAlliance = alliance?.map((data) => data.id.toString());
    this.userForm.setValue({
      name: firstName,
      lastName,
      email,
      organisationName,
      allianceId: allAlliance || null,
      mobile: phone,
      role: [],
    });

    const roleFormArray = this.userForm.get("role") as FormArray;
    roles.forEach((role) => {
      roleFormArray.push(this.formBuilder.control(role));
      this.userRoles.push({ id: role.id, name: role.name });
    });
  }

  /**
   * @description Handles the change in role selection.
   * @param data Contains the role and its checked status.
   */
  public handleRoleChange({ role, checked }: { role: UserRole; checked: boolean }): void {
    const roleFormArray = this.userForm.get("role") as FormArray;
    const roleIndex = this.userRoles.findIndex((r) => r.id === role.id);

    if (checked) {
      if (roleIndex === -1) {
        this.userRoles.push({ id: role.id, name: role.name });
        roleFormArray.push(this.formBuilder.control(role));
      }
    } else {
      if (roleIndex !== -1) {
        this.userRoles.splice(roleIndex, 1);
        roleFormArray.removeAt(roleIndex);
      }
    }
  }

  /**
   * @description Toggles the role editing mode.
   */
  public toggleRoleEditing(): void {
    this.isEditingRoles = !this.isEditingRoles;
  }

  /**
   * @description Gets the names of user roles as a comma-separated string.
   * @returns A string of role names.
   */
  public getUserRoles(): string {
    return this.userRoles.map((role) => role.name).join(", ");
  }

  /**
   * @description Submits the form data to create or update a user.
   */
  public submit(): void {
    const formValues = this.userForm.value;
    const userPayload: UserCreateOrUpdate = {
      firstName: formValues.name ?? "",
      lastName: formValues.lastName ?? "",
      email: formValues.email ?? "",
      organisationName: formValues.organisationName,
      allianceId: formValues.allianceId,
      phone: formValues.mobile ?? "",
      role: formValues.role as UserRole[],
    };

    if (this.userId) {
      this.updateUser(userPayload);
    } else {
      this.createUser(userPayload);
    }
  }

  /**
   * @description Creates a new user with the given payload.
   * @param payload The data to create a new user.
   */
  private async createUser(payload: Partial<User>): Promise<void> {
    try {
      if (this.userForm.valid) {
        await this.userService.createUser(payload);
        this.router.navigate(["/user"]);
      } else {
        logFormErrors(this.userForm);
        this.toastrService.warning("Form Invalid");
        this.markAllControlsTouched(this.userForm);
      }
    } catch (error) {
      logger.error("Error creating user", error);
    }
  }

  /**
   * @description Updates the existing user with the given payload.
   * @param payload The data to update the user.
   */
  private async updateUser(payload: Partial<User>): Promise<void> {
    if (this.userId) {
      try {
        if (this.userForm.valid) {
          await this.userService.update(this.userId, payload);
          this.router.navigate(["/user"]);
        } else {
          logFormErrors(this.userForm);
          this.toastrService.warning("Form Invalid");
          this.markAllControlsTouched(this.userForm);
        }
      } catch (error) {
        logger.error("Error updating user", error);
      }
    }
  }

  /**
   * @description Open the password modalto updat user password
   */
  open(content: TemplateRef<unknown>) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "share-modal",
    });
  }
  /**
   * @description This method checks weather the new and confirm password are same or not and returns error if not same
   * @param formGroup
   * @returns
   */
  passwordMatchValidator(formGroup: FormGroup) {
    const newPasswordControl = formGroup.get("newPassword");
    const cnfPasswordControl = formGroup.get("cnfPassword");

    if (!newPasswordControl || !cnfPasswordControl) {
      return;
    }

    const newPassword = newPasswordControl.value;
    const cnfPassword = cnfPasswordControl.value;

    if (newPassword !== cnfPassword) {
      cnfPasswordControl.setErrors({ mismatch: true });
    } else {
      cnfPasswordControl.setErrors(null);
    }
  }
  checkClose() {
    this.passwordForm.reset();
    this.formInvalid = false;
    this.modalService.dismissAll();
  }

  /**
   * @description this method is used to submit the password form to update the password of any user
   */
  onSubmit() {
    const body: passWordUpdate = {
      userId: this.user?.id,
      oldPassword: this.passwordForm.value.oldPassword,
      password: this.passwordForm.value.newPassword,
    };
    if (this.passwordForm.valid) {
      this.modalService.dismissAll();
      this.updateUserPassword(body);
    } else {
      logFormErrors(this.passwordForm);
      this.toastrService.warning("Form Invalid");
      this.markAllControlsTouched(this.passwordForm);
    }
  }

  /**
   * @description This method marks all the invalid fields of any of the form
   * @param formGroup
   */

  private markAllControlsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAllControlsTouched(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((formControl) => {
          if (formControl instanceof FormControl) {
            formControl.markAsTouched({ onlySelf: true });
          } else if (formControl instanceof FormGroup) {
            this.markAllControlsTouched(formControl);
          }
        });
      }
    });
  }
  /**
   * @description Updates the existing user's password with the given payload.
   * @param payload The data to update the user.
   */
  private async updateUserPassword(payload: Partial<passWordUpdate>): Promise<void> {
    if (this.user?.id) {
      try {
        await this.userService.updatePassword(payload);
      } catch (error) {
        logger.error("Error updating user", error);
      }
    }
  }

  /**
   *  @description this method is use to redirect the user to user listing when clicking oh cancel
   */
  public onCancel() {
    this.router.navigate(["/user"]);
  }

  get passwordFormControl() {
    return this.passwordForm.controls;
  }

  async archiveUser() {
    await this.userService.delete(this.user.id);
    this.router.navigate(["/user"]);
  }
}
