import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatTable, MatTableModule } from "@angular/material/table";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TAPIListResult } from "src/app/contract/api.contract";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { FileUploadService } from "src/app/service/fileUpload.service";
import { LoadingService } from "src/app/service/loading.service";
import { logger } from "src/app/util/logger.util";

const className = "TableComponent";

export interface TableAction<T> {
  type: "button" | "link" | "toggle";
  label: string;
  icon?: string;
  iconUrl?: string;
  action?: (element: T, checked?: boolean) => void;
  routerLink?: string[];
  checked?: boolean;
}

export type TableColumn<T> = {
  columnDef: string;
  columnLabel?: string;
  isInnerHtml?: boolean;
  render?: (element: T) => string | number | Date | null | undefined;
  cssClasses?: (element: T) => string;
  actions?: (element: T) => TableAction<T>[];
};

@Component({
  selector: "app-table",
  standalone: true,
  imports: [MatTableModule, MatPaginatorModule, MatIcon],
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
})
export class TableComponent<T> implements OnInit {
  protected sanitizer = inject(DomSanitizer);
  private loadingService = inject(LoadingService);
  private fileUploadService = inject(FileUploadService);

  public query: IQueryFilter = new IQueryFilter();

  @Input() tableTitle: string = "";
  @Input({ required: true }) tableColumns: TableColumn<T>[];
  @Input() fetchDataMethod: (query: Partial<IQueryFilter>) => Promise<TAPIListResult<T>>;
  @Input() rowClickMethod: ((data: T) => void) | null = null;
  @Input() usePagination: boolean = false;
  @Input() paginationSize: number = this.query.limit;
  @ViewChild(MatTable) matTable: MatTable<T>;
  @Output() actionTriggered = new EventEmitter<{ action: (element: T) => void; element: T }>();
  @Input() data: T[];

  dataSource: TAPIListResult<T> = { count: 0, rows: [] };
  displayedColumns: string[] = [];
  paginationIndex = 0;

  ngOnInit(): void {
    this.displayedColumns = this.tableColumns.map((e) => e.columnDef);
    this._fetchData();
  }

  handleRowClick(row: T) {
    if (this.rowClickMethod) {
      this.rowClickMethod(row);
    }
  }

  async handlePaginationEvent(event: PageEvent) {
    this.paginationIndex = event.pageIndex;
    await this._fetchData();
  }

  private async _fetchData() {
    const signature = className + "._fetchData: ";
    if (this.fetchDataMethod) {
      this.loadingService.blockWithLoadingOverlayPromise(async () => {
        this.dataSource = await this.fetchDataMethod({
          skip: this.paginationIndex * this.paginationSize,
          limit: this.paginationSize,
          filter: this.query.filter,
        });
      });

      if (!this.matTable) {
        logger.warn(signature + `Preveting uncaught during component destroy`);
        return;
      }

      this.matTable.renderRows();
    }
  }

  onActionClick(action: (element: T) => void, element: T) {
    if (action) {
      action(element);
    }
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  handleToggleChange(action: TableAction<T>, event: Event, element: T) {
    const target = event.target as HTMLInputElement;
    if (action.action) {
      action.action(element, target.checked);
    }
  }

  public refreshTableData(): void {
    this._fetchData();
  }

  getDownloadLink(fileUrl: string | undefined) {
    if (fileUrl) {
      const key = fileUrl.replace("https://files-qwrap-dev.s3.ap-southeast-2.amazonaws.com/", "");
      this.fileUploadService.getDownloadSignedUrl(key).subscribe(
        (response) => {
          window.open(response.url, "_blank");
        },
        (error) => {
          console.error("Error fetching signed URL", error);
        },
      );
    }
  }
}
