@if (tableTitle) {
  <div class="title-bg mb-0">
    <h2>{{ tableTitle }}</h2>
  </div>
}
<div class="main-contact-form-section p-0">
  <div class="cash-in-table support">
    <div class="part-table table-responsive">
      <table
        mat-table
        [dataSource]="data || dataSource.rows"
        class="custom-table table table-bg-even m-0"
        [class.has-clickable-rows]="rowClickMethod !== null"
      >
        @for (column of tableColumns; track column) {
          <ng-container [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef>
              {{ column.columnLabel || "" }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class]="column.cssClasses ? column.cssClasses(element) : ''"
            >
              @if (column.columnDef === "file") {
                <a
                  href="javascript:void(0)"
                  (click)="
                    getDownloadLink(column.render ? column.render(element)?.toString() : undefined)
                  "
                >
                  {{
                    column.render
                      ? column.render(element)?.toString()?.split("/")?.pop()
                      : element[column.columnDef]
                  }}
                </a>
              } @else {
                @if (element["isInnerHtml"] || column.isInnerHtml) {
                  <div
                    [innerHTML]="column.render ? column.render(element) : element[column.columnDef]"
                  ></div>
                } @else {
                  {{ column.render ? column.render(element) : element[column.columnDef] }}
                }
              }
              @if (column.actions) {
                @for (action of column.actions(element); track $index) {
                  @switch (action.type) {
                    @case ("button") {
                      <button (click)="action.action ? action.action(element) : null">
                        @if (action.icon) {
                          <mat-icon [fontIcon]="action.icon"></mat-icon>
                        }
                        {{ action.label }}
                      </button>
                    }
                    @case ("toggle") {
                      <input
                        type="checkbox"
                        [checked]="action.checked"
                        (change)="handleToggleChange(action, $event, element)"
                      />
                    }
                  }
                }
              }
            </td>
          </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="handleRowClick(row)"
        ></tr>
      </table>
    </div>
  </div>
</div>

@if (usePagination) {
  <mat-paginator
    aria-label="Select page"
    [length]="dataSource.count"
    [pageSize]="paginationSize"
    [pageIndex]="paginationIndex"
    showFirstLastButtons
    hidePageSize
    (page)="handlePaginationEvent($event)"
  />
}
