import { Component } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { provideMomentDateAdapter } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import * as moment from "moment";

const DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-date-picker",
  standalone: true,
  imports: [FormsModule, MatInputModule, MatDatepickerModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent,
    },
    provideMomentDateAdapter(DATE_FORMATS),
  ],
  templateUrl: "./date-picker.component.html",
})
export class DatePickerComponent implements ControlValueAccessor {
  date: moment.Moment | null = null;
  // minDate: moment.Moment = moment(); // Disable prior dates

  onChange = (date: moment.Moment) => {};
  onTouched = () => {};

  touched = false;
  disabled = false;

  onDateChange(event) {
    this.markAsTouched();
    if (!this.disabled) {
      const newValue = event.value;
      this.date = newValue;
      this.onChange(newValue);
    }
  }

  writeValue(date: moment.Moment) {
    this.date = date;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
