import { DatePipe } from "@angular/common";
import { Component, inject, Input, ViewChild } from "@angular/core";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { BidpoolFeedback } from "src/app/model/bidpool/bidpool-feedback.model";
import { BidpoolService } from "src/app/service/bidpool.service";

@Component({
  selector: "app-bidpool-feedback",
  standalone: true,
  imports: [TableComponent],
  providers: [DatePipe],
  templateUrl: "./bidpool-feedback.component.html",
})
export class BidpoolFeedbackComponent {
  @Input() bidPoolId: number;
  @ViewChild(TableComponent) tableComponent!: TableComponent<null>;
  protected datePipe = inject(DatePipe);

  constructor(private readonly bidpoolService: BidpoolService) {}

  protected feedbackTableColumns: TableColumn<BidpoolFeedback>[] = [
    {
      columnDef: "feedback",
      columnLabel: "Feedback",
      isInnerHtml: true,
    },
    {
      columnDef: "user",
      render: (element: BidpoolFeedback) => element.user?.fullName || "",
    },
    {
      columnDef: "createdAt",
      render: (e: BidpoolFeedback) => this.datePipe.transform(e.createdAt, "d MMMM y"),
    },
    {
      columnDef: "time",
      render: (e: BidpoolFeedback) => this.datePipe.transform(e.createdAt, "HH: mm"),
    },
  ];

  protected feedbackFetchDataMethod = (query: Partial<IQueryFilter>) => {
    query.order = "desc";
    query.filter = { bidpoolApplicationId: this.bidPoolId };
    return this.bidpoolService.fetchBidpoolFeedbacks(new IQueryFilter(query));
  };

  public refreshTableData(): void {
    this.tableComponent.refreshTableData();
  }
}
