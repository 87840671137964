import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { RefAlliance } from "../ref/ref-alliance.model";
import { WorkflowStep } from "../workflow/workflow-step.model";
import {
  BidpoolContribution,
  BPRequestContributorName,
  OtherOrgsContributorName,
} from "./bidpool-contribution.model";
import { BidpoolExpectedBenefit } from "./bidpool-expected-benefit.model";
import { BidpoolMilestone } from "./bidpool-milestone.model";
import { BidpoolStrategicPriority } from "./bidpool-strategic-priority.model";
import { BidpoolUser } from "./bidpool-user.model";

export class BidpoolApplication extends BaseModel {
  id: number;
  name: string;
  allianceId: number;
  @Type(() => RefAlliance)
  alliance: RefAlliance | null;
  dateEndorsement: string | null;
  dateSubmitted: Date | null;
  title: string;
  brief: string;
  description: string;
  @Type(() => BidpoolUser)
  users: BidpoolUser[];
  workflowStepId: number;
  @Type(() => WorkflowStep)
  workflowStep: WorkflowStep;
  @Type(() => BidpoolStrategicPriority)
  strategicPriorities: BidpoolStrategicPriority[];
  @Type(() => BidpoolExpectedBenefit)
  expectedBenefits: BidpoolExpectedBenefit[];
  @Type(() => BidpoolMilestone)
  milestones: BidpoolMilestone[] = [];
  @Type(() => BidpoolContribution)
  contributions: BidpoolContribution[] = [];
  amountRequested: number;
  amountApproved: number;

  /**
   * @description Main contact fullName
   */
  public get contact(): BidpoolUser | null {
    return this.contacts[0] || null;
  }

  public get contacts(): BidpoolUser[] {
    return (this.users || []).filter((c) => c.type === "contact");
  }

  public get participants(): BidpoolUser[] {
    return (this.users || [])
      .filter((c) => c.type === "participant")
      .sort((a, b) => a.roles[0].id - b.roles[0].id);
  }

  /**
   * @description BidpoolApplication regional alliance, equivalent to Main contact regional alliance
   */
  public get regionalAlliance(): string {
    return this.alliance ? this.alliance.short : "";
  }

  public get contributionsCashTotal() {
    return this.contributions.reduce((acc, curr) => acc + (curr.cash || 0), 0);
  }

  public get contributionsInkindSubTotal() {
    return this.contributions
      .filter((c) => c.isOtherOrganisation !== true && c.contributor !== BPRequestContributorName)
      .reduce((acc, curr) => acc + ((curr.inkind && +curr.inkind) || 0), 0);
  }

  public get contributionsCashSubTotal() {
    return this.contributions
      .filter((c) => c.isOtherOrganisation !== true && c.contributor !== BPRequestContributorName)
      .reduce((acc, curr) => acc + ((curr.cash && +curr.cash) || 0), 0);
  }

  public get contributionsInkindTotal() {
    return this.contributions.reduce((acc, curr) => acc + ((curr.inkind && +curr.inkind) || 0), 0);
  }
}
