<form [formGroup]="checklistForm">
  <div>
    <mat-checkbox formControlName="precond_1"
      >Preliminary discussion of scope with regional QWRAP Group</mat-checkbox
    >
  </div>
  <div>
    <mat-checkbox formControlName="precond_2"
      >Minimum of 2 participating councils agreeing to the project</mat-checkbox
    >
  </div>
  <div>
    <mat-checkbox formControlName="precond_3"
      >Project planned and endorsed by regional QWRAP Group</mat-checkbox
    >
  </div>
  <div>
    <mat-checkbox formControlName="precond_4"
      >Project work cannot commence until bidpool application is approved</mat-checkbox
    >
  </div>
  <div>
    <mat-checkbox formControlName="precond_5"
      >Requirements for Project Finalisation Report reviewed and reasonably expected to be
      achievable</mat-checkbox
    >
  </div>
  <div>
    <mat-checkbox formControlName="precond_6">Commitment to complete </mat-checkbox>
    <a
      href="../../../../assets/files/QWRAP Project Finalisation Report V2.docx"
      class="text-decoration-underline ms-1 fw-bold"
      target="_blank"
      role="button"
      >Project Finalisation Report
    </a>
  </div>
</form>
