<app-authenticated-layout>
  @if (regionalCoordinatorApplication && mode === "view") {
    <ng-container *ngTemplateOutlet="viewMode"></ng-container>
  } @else if (regionalCoordinatorApplication && mode === "edit") {
    <ng-container *ngTemplateOutlet="editMode"></ng-container>
  }
</app-authenticated-layout>

<ng-template #viewMode>
  @let thisRCApplication = regionalCoordinatorApplication!;

  <div class="d-flex flex-column">
    <div
      class="d-flex justify-content-between mb-3"
      [class.approved]="thisRCApplication.workflowStepId == rcWorkflowStepEnum.Approved"
    >
      <div class="d-flex align-items-center gap-3">
        <h1>Regional Coordinator Application No. {{ thisRCApplication.name }}</h1>
        <div class="tag-indicator status">
          <span>{{ thisRCApplication.workflowStep.name }}</span>
        </div>
      </div>
      @if (canEdit) {
        <div class="main-contact-add-btn d-flex justify-content-end align-items-center mb-3">
          <button class="btn-regular-blue" (click)="enterEditMode()">
            <mat-icon fontIcon="border_color"></mat-icon>
            Edit application
          </button>
        </div>
      }
    </div>

    <app-table
      class="mb-3"
      [tableColumns]="contactTable.tableColumns"
      [data]="contactTable.tableData"
    />

    <app-table
      class="mb-3"
      [tableColumns]="participantsTable.tableColumns"
      [data]="participantsTable.tableData"
    />

    <h5 class="mb-2 mt-3">Chair contact information</h5>
    <app-table
      class="mb-3"
      [tableColumns]="chairContactTable.tableColumns"
      [data]="chairContactTable.tableData"
    />

    <app-table
      class="mb-3"
      [tableColumns]="coordinatorPositionTable.tableColumns"
      [data]="coordinatorPositionTable.tableData"
    />

    <app-table
      class="mb-3"
      [tableColumns]="projectInfoTable.tableColumns"
      [data]="projectInfoTable.tableData"
    />

    <app-table class="mb-3" [tableColumns]="kpiTable.tableColumns" [data]="kpiTable.tableData" />

    <h5 class="mb-2 mt-3">Proposed projects/activities for coming year</h5>
    <app-table
      class="mb-3"
      [tableColumns]="rcComingProjectTable.tableColumns"
      [data]="rcComingProjectTable.tableData"
    />

    <h5 class="mb-2 mt-3">Projects and activities in progress from previous years</h5>
    <app-table
      class="mb-3"
      [tableColumns]="rcPreviousProjectTable.tableColumns"
      [data]="rcPreviousProjectTable.tableData"
    />

    <section class="bidpool-project-info">
      <div class="participants_main">
        <div class="part-table table-responsive mb-3">
          <table class="table" style="font-family: Arial, sans-serif">
            <thead>
              <tr>
                <th>Checklist for submission of Annual Workplan:</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              @for (data of regionalCoordinatorApplication!.rcAnnualWorkPlan; track data) {
                <tr>
                  <td>
                    {{ data.label }}
                    <p *ngIf="data.labelId == 1">
                      Submission of Annual Workplan (this online form) constitutes agreement to:
                    </p>
                  </td>
                  <td>{{ data.value }}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <section class="bidpool-project-info" id="supporting-documents">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>
            Supporting documents
            <p class="mat-typography fs-10 text-muted text-wrap">
              The following file types are supported for upload:
              <strong>XLSX, DOCX, PPTX, PDF, JPG, PNG, TXT, CSV</strong>.
            </p>
          </h2>
        </div>
      </div>
      <div class="milestones-main">
        <div class="participants_main">
          @if (thisRCApplication.workflowStepId !== rcWorkflowStepEnum.Completed) {
            <div class="d-flex align-items-center justify-content-end mb-3">
              <div class="part-btn">
                <button
                  class="btn-regular-blue"
                  type="button"
                  (click)="openDocumentModel(documentModel)"
                >
                  <mat-icon fontIcon="upload" />
                  UPLOAD FILE
                </button>
              </div>
            </div>
          }
          <div class="part-table table-responsive mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Date/time</th>
                  <th>Uploaded by</th>
                  <th>Description</th>
                  <th>File type</th>
                  <th>Files uploaded</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (documents of rcDocuments.rows; track documents) {
                  <tr>
                    <td>{{ documents.createdAt | date: "dd MMM yyyy H:mm" }}</td>
                    <td>{{ documents.user.firstName + " " + documents.user.lastName }}</td>
                    <td>{{ documents.description }}</td>
                    <td>
                      <mat-icon>{{ getFileTypeIcon(documents.type) }}</mat-icon>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="getDownloadLink(getDocument(documents.documentFile))"
                      >
                        {{ getDocument(documents.documentFile).split("/").pop() }}
                      </a>
                    </td>
                    <td>
                      @if (thisRCApplication.workflowStepId !== rcWorkflowStepEnum.Completed) {
                        <button type="button" (click)="deleteDocument(documents.id)">
                          <mat-icon fontIcon="delete" />
                        </button>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <mat-paginator
            aria-label="Select page"
            [length]="rcDocuments.count"
            pageSize="5"
            [pageIndex]="documentPaginationIndex"
            showFirstLastButtons
            hidePageSize
            (page)="handlePaginationEvent($event)"
          />
        </div>
      </div>
    </section>

    <app-regional-coordinator-feedback [rcId]="rcId"></app-regional-coordinator-feedback>

    @if (
      thisRCApplication.workflowStepId != rcWorkflowStepEnum.Draft &&
      thisRCApplication.workflowStepId != rcWorkflowStepEnum.Completed
    ) {
      <div class="form-group">
        <label for="bp-feedback">Feedback</label>
        <div class="d-flex align-items-start gap-3">
          <mat-form-field class="form-control" appearance="outline">
            <textarea
              cdkTextareaAutosize
              id="bp-feedback"
              matInput
              matTooltip="Feedback"
              placeholder="Feedback"
              [formControl]="feedback"
            ></textarea>
          </mat-form-field>
          <div class="main-btn-group d-flex mt-2">
            <button class="btn-regular-blue light" (click)="saveFeedback()">
              {{ saving ? "saving..." : "save" }}
            </button>
          </div>
        </div>
      </div>
    }

    @if (canChangeWorkflowStep) {
      <div class="main-btn-group d-flex">
        @switch (thisRCApplication.workflowStepId) {
          @case (rcWorkflowStepEnum.R1) {
            <button class="btn-regular-blue light" (click)="putBackToDraft()">
              Put back to draft
            </button>
            <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
              Send to Banker
            </button>
          }
          @case (rcWorkflowStepEnum.R2) {
            <button class="btn-regular-blue light" (click)="putBackToDraft()">
              Put back to draft
            </button>
            <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
              Send to Working Group
            </button>
          }
          @case (rcWorkflowStepEnum.AWG) {
            <button class="btn-regular-blue light" (click)="putBackToDraft()">
              Put back to draft
            </button>
            <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
              Approve
            </button>
          }
          @case (rcWorkflowStepEnum.Approved) {
            <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
              Complete
            </button>
          }
          @default {}
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #editMode>
  @let thisRCApplication = regionalCoordinatorApplication!;
  <div class="sticky-top" id="sticky-top">
    <div class="bidpool-top-main">
      <div class="d-flex align-items-center gap-15">
        <div class="bid-app-no">
          <h1>
            <a routerLink="." queryParamsHandling="merge"
              >Regional Coordinator Application No. {{ thisRCApplication.name }}</a
            >
          </h1>
        </div>
        @if (saving) {
          <div class="tag-indicator saving">
            <mat-icon fontIcon="cloud_sync" />
            <span>Saving</span>
          </div>
        } @else {
          <div class="tag-indicator saved">
            <mat-icon fontIcon="cloud_done" />
            <span>Saved</span>
          </div>
        }
        <div>Last saved {{ thisRCApplication.updatedAt | date: "dd MMM yyyy H:mm:ss" }}</div>
      </div>
    </div>

    <div class="under-main-menu mb-3">
      <div class="under-main-items">
        <a routerLink="." queryParamsHandling="merge" fragment="contacts" class="bid-menu-item"
          >Contacts</a
        >
        <a routerLink="." queryParamsHandling="merge" fragment="coordinator" class="bid-menu-item"
          >Coordinator and KPIs</a
        >
        <a
          routerLink="."
          queryParamsHandling="merge"
          fragment="annual-work-plan"
          class="bid-menu-item"
          >Annual work plan</a
        >
        <a routerLink="." queryParamsHandling="merge" fragment="milestones" class="bid-menu-item"
          >Funding</a
        >
        <a
          routerLink="."
          queryParamsHandling="merge"
          fragment="supporting-documents"
          class="bid-menu-item"
          >Supporting documents</a
        >
      </div>
    </div>
  </div>

  <form [formGroup]="regionalCoordinatorForm" (ngSubmit)="onSubmit()">
    <section class="bidpool-project-info" id="contacts">
      <div class="milestones-main">
        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-end">
            <div class="part-btn">
              <mat-form-field class="form-control autocomplete-btn">
                <mat-label>Add chair contact</mat-label>
                <input
                  type="text"
                  matTooltip="Search..."
                  placeholder="Search..."
                  aria-label="Add chair Conatct"
                  matInput
                  [formControl]="chairContactAutocompleteControl"
                  [matAutocomplete]="chairContactAutocomplete"
                />
                <mat-autocomplete
                  #chairContactAutocomplete="matAutocomplete"
                  [displayWith]="displayContact"
                  (optionSelected)="addRow({ fieldName: 'chairContacts', event: $event })"
                >
                  @for (option of chairContactOptions | async; track option) {
                    <mat-option [disabled]="isUserAlreadySelected(option)" [value]="option">{{
                      option.fullName
                    }}</mat-option>
                  }
                  @if (!(chairContactOptions | async)?.length) {
                    <mat-option disabled>No user found</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <section class="bidpool-project-info" id="contacts" formArrayName="chairContacts">
            <div class="project-head">
              <div class="main-contact-heading">
                <h2>Chair contact information</h2>
              </div>
            </div>
            <div class="milestones-main">
              <div class="part-table table-responsive mb-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Name of Alliance or Regional Group</th>
                      <th>Contact person</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Date of endorsement</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (
                      chairContact of getUniqueChairContacts(thisRCApplication.chairContacts);
                      track chairContact;
                      let i = $index
                    ) {
                      <tr [formGroupName]="i">
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input matInput [value]="chairContact.user.regionalAlliance" readonly />
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input matInput [value]="chairContact.user.fullName" readonly />
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input matInput formControlName="phone" />
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input matInput [value]="chairContact.user.email" readonly />
                          </mat-form-field>
                        </td>
                        <td>
                          <app-date-picker formControlName="dateEndorsement" />
                        </td>
                        <td>
                          <button type="button" (click)="deleteRow('chairContacts', i)">
                            <mat-icon fontIcon="delete" />
                          </button>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>

        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-between">
            <div class="part-head">
              <h2>Main Contacts</h2>
            </div>
          </div>
          <div class="part-table table-responsive mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Contact person</th>
                  <th>Name of regional alliance</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                @for (
                  contact of thisRCApplication.contacts;
                  track contact;
                  let i = $index;
                  let count = $count
                ) {
                  <tr>
                    <td>{{ contact.fullName }}</td>
                    <td>{{ contact.regionalAlliance }}</td>
                    <td>{{ contact.phone }}</td>
                    <td>{{ contact.email }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-between">
            <div class="part-head">
              <h2>Participants in the process</h2>
              <p class="mat-typography fs-10 text-muted">
                Please add the banker and working group as participants.
              </p>
            </div>
            <div class="part-btn">
              <mat-form-field class="form-control autocomplete-btn">
                <mat-label>Add new participant</mat-label>
                <input
                  type="text"
                  matTooltip="Search..."
                  placeholder="Search..."
                  aria-label="Add new participant"
                  matInput
                  [formControl]="participantAutocompleteControl"
                  [matAutocomplete]="participantAutocomplete"
                />
                <mat-autocomplete
                  #participantAutocomplete="matAutocomplete"
                  [displayWith]="displayContact"
                  (optionSelected)="addRow({ fieldName: 'participants', event: $event })"
                >
                  @for (option of participantOptions | async; track option) {
                    <mat-option [disabled]="isUserAlreadySelected(option)" [value]="option">{{
                      option.fullName
                    }}</mat-option>
                  }
                  @if (!(participantOptions | async)?.length) {
                    <mat-option disabled>No user found</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="part-table table-responsive mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email address</th>
                  <th class="text-center">Banker</th>
                  <th class="text-center">Working Group</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (
                  participant of thisRCApplication.participants;
                  track participant;
                  let i = $index
                ) {
                  <tr>
                    <td>{{ participant.fullName }}</td>
                    <td>{{ participant.email }}</td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="participant.hasRole(rolesEnum.Reviewer2)"
                        disabled
                      ></mat-checkbox>
                    </td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="participant.hasRole(rolesEnum.ApproverWG)"
                        disabled
                      ></mat-checkbox>
                    </td>
                    <td>
                      <button type="button" (click)="deleteRow('participants', i)">
                        <mat-icon fontIcon="delete" />
                      </button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div class="participants_main" [formGroup]="regionalCoordinatorForm">
          <section class="bidpool-project-info">
            <div class="project-head">
              <div class="main-contact-heading">
                <h2>Coordinator position</h2>
              </div>
            </div>
            <div class="milestones-main">
              <div class="part-table table-responsive mb-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Name of coordinator</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Start date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [formGroup]="regionalCoordinatorForm.controls.coordinatorPosition">
                      <td>
                        <mat-form-field class="form-control" appearance="outline">
                          <input matInput formControlName="name" />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="form-control" appearance="outline">
                          <input matInput formControlName="phone" />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="form-control" appearance="outline">
                          <input matInput formControlName="email" />
                        </mat-form-field>
                      </td>
                      <td>
                        <app-date-picker formControlName="startDate"></app-date-picker>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>

        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-between">
            <div class="part-head">
              <h2></h2>
            </div>
          </div>
          <section class="bidpool-project-info">
            <div class="start-pro info-main form-field-cst d-flex flex-column">
              <div class="form-group">
                <label for="intro"
                  >Introduction
                  <p class="mat-typography fs-10 text-muted text-wrap">
                    Brief description of the structure of the region (e.g. technical group and
                    political group?), chair and coordinator and how the coordinator will be
                    employed.
                  </p>
                </label>
                <mat-form-field class="form-control" appearance="outline">
                  <textarea
                    id="intro"
                    cdkTextareaAutosize
                    matInput
                    formControlName="introduction"
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="form-group">
                <label for="intro"
                  >Strategic objectives
                  <p class="mat-typography fs-10 text-muted text-wrap">
                    Summary of the specific strategic objectives and broad strategic objectives for
                    the next 3 years and how the Annual Work Plan will contribute to them. The
                    alignment of these objectives with any overarching Regional Plan or individual
                    Council plans must be specified here.
                  </p>
                </label>
                <mat-form-field class="form-control" appearance="outline">
                  <textarea
                    id="intro"
                    cdkTextareaAutosize
                    matInput
                    formControlName="strategicObject"
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="form-group">
                <label for="intro"
                  >Regional maturity
                  <p class="mat-typography fs-10 text-muted">
                    Assessment of the region’s collaboration maturity and how this will be furthered
                    in the coming year.
                  </p>
                </label>
                <mat-form-field class="form-control" appearance="outline">
                  <textarea
                    id="intro"
                    cdkTextareaAutosize
                    matInput
                    formControlName="regionalMaturity"
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="form-group">
                <label for="intro"
                  >Role of the coordinator and KPIs
                  <p class="mat-typography fs-10 text-muted text-wrap">
                    Specifiy the broad expectations for the Regional Coordinator and any KPIs that
                    will be used to assess their performance. The below Coordinator and KPIs table
                    provides a list of possible Regional Coordinator functions
                  </p>
                </label>
                <mat-form-field class="form-control" appearance="outline">
                  <textarea
                    id="intro"
                    cdkTextareaAutosize
                    matInput
                    formControlName="roleOfCoordinator"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>

    <section class="bidpool-project-info" formArrayName="rcKpis" id="coordinator">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>
            Coordinator KPIs
            <p class="mat-typography fs-10 text-muted">
              Please ensure the total Weight % is equal to 100%
            </p>
          </h2>
        </div>
      </div>
      <div class="milestones-main">
        <div class="part-table table-responsive mb-3">
          <table class="table">
            <thead>
              <tr>
                <th>KPI</th>
                <th>KPI related activities</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              @for (kpi of thisRCApplication.rcKpis; track kpi; let i = $index) {
                <tr [formGroupName]="i">
                  <td>
                    {{ kpi.label }}
                  </td>
                  <td>
                    <div [innerHTML]="getKpiListItemsHtml(kpi.label)"></div>
                  </td>
                  <td>
                    <div class="kpi-weight">
                      <mat-form-field class="form-control" appearance="outline">
                        <input matInput formControlName="weight" />
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <section class="bidpool-project-info" id="annual-work-plan">
      <div class="project-head">
        <div class="main-contact-heading d-flex align-items-center justify-content-between">
          <h2>Annual Work Plans</h2>
        </div>
      </div>

      <div class="milestones-main">
        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="part-head">
              <h2>Proposed projects and activities for coming year</h2>
            </div>

            <div class="part-btn">
              <button
                class="btn-regular-blue"
                type="button"
                (click)="openProject(prevProject, 'next')"
              >
                <mat-icon fontIcon="add" />
                Add new project
              </button>
            </div>
          </div>
          <div class="part-table table-responsive mb-3">
            <app-table
              class="mb-3"
              [tableColumns]="rcComingYearTable.tableColumns"
              [data]="rcComingYearTable.tableData"
              (actionTriggered)="onTableAction($event)"
            />
          </div>
        </div>
      </div>

      <div class="milestones-main">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="part-head">
            <h2>Projects and activities in progress from previous years</h2>
          </div>
          <div class="part-btn">
            <button
              class="btn-regular-blue"
              type="button"
              (click)="openProject(prevProject, 'prev')"
            >
              <mat-icon fontIcon="add" />
              Add new project
            </button>
          </div>
        </div>
        <div class="participants_main">
          <div class="part-table table-responsive mb-3">
            <app-table
              class="mb-3"
              [tableColumns]="rcPreviousYearTable.tableColumns"
              [data]="rcPreviousYearTable.tableData"
              (actionTriggered)="onTableAction($event)"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="bidpool-project-info" id="milestones">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>Funding</h2>
        </div>
      </div>

      <div class="milestones-main p-0 fundinmain">
        <div class="fndinrdoc">
          <div class="p-3 tpullst">
            Application for funding means there is agreement to the following conditions:
          </div>
          <ul class="ps-5 pb-3">
            <li>
              A commitment to convene at least <span class="underline">4 meetings</span> of the
              alliance or regional group per year.
            </li>
            <li>
              Written updates on progress towards the planned objectives will be provided in April
              and November of each year and a final summary report of outcomes at the end of the
              position funding.
            </li>
          </ul>
        </div>
        <div class="fundbtm row p-0 m-0">
          <div class="amtd col-6 p-3">Amount Requested:</div>
          <div class="fndinp col-6 p-3">
            <input
              type="number"
              matInput
              class="form-control p-0"
              formControlName="amountRequested"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="bidpool-project-info" id="supporting-documents">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>
            Supporting documents
            <p class="mat-typography fs-10 text-muted text-wrap">
              The following file types are supported for upload:
              <strong>XLSX, DOCX, PPTX, PDF, JPG, PNG, TXT, CSV</strong>.
            </p>
          </h2>
        </div>
      </div>
      <div class="milestones-main">
        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-end mb-3">
            <div class="part-btn">
              <button
                class="btn-regular-blue"
                type="button"
                (click)="openDocumentModel(documentModel)"
              >
                <mat-icon fontIcon="upload" />
                UPLOAD FILE
              </button>
            </div>
          </div>
          <div class="part-table table-responsive mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Date/time</th>
                  <th>Uploaded by</th>
                  <th>Description</th>
                  <th>File type</th>
                  <th>Files uploaded</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (documents of rcDocuments.rows; track documents) {
                  <tr>
                    <td>{{ documents.createdAt | date: "dd MMM yyyy H:mm" }}</td>
                    <td>{{ documents.user.firstName + " " + documents.user.lastName }}</td>
                    <td>{{ documents.description }}</td>
                    <td>
                      <mat-icon>{{ getFileTypeIcon(documents.type) }}</mat-icon>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="getDownloadLink(getDocument(documents.documentFile))"
                      >
                        {{ getDocument(documents.documentFile).split("/").pop() }}
                      </a>
                    </td>
                    <td>
                      <button type="button" (click)="deleteDocument(documents.id)">
                        <mat-icon fontIcon="delete" />
                      </button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <mat-paginator
            aria-label="Select page"
            [length]="rcDocuments.count"
            pageSize="5"
            [pageIndex]="documentPaginationIndex"
            showFirstLastButtons
            hidePageSize
            (page)="handlePaginationEvent($event)"
          />
        </div>
      </div>
    </section>

    <section class="bidpool-project-info" id="workplan" formArrayName="rcAnnualWorkPlan">
      <div class="project-head">
        <div class="main-contact-heading d-flex align-items-center justify-content-between">
          <h2>Checklist for submission of Annual Workplan:</h2>
        </div>
      </div>

      <div class="milestones-main">
        <div class="participants_main">
          <div>
            @for (workPlan of rcAnnualWorkPlan.controls; track workPlan; let i = $index) {
              <div class="form-group form-inrf">
                <mat-checkbox [formControlName]="i">
                  {{ thisRCApplication.rcAnnualWorkPlan[i].label }}
                </mat-checkbox>
                <p class="ms-2" *ngIf="thisRCApplication.rcAnnualWorkPlan[i].labelId == 1">
                  Submission of Annual Workplan (this online form) constitutes agreement to:
                </p>
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    <section class="bidpool-project-info">
      <app-regional-coordinator-feedback [rcId]="rcId"></app-regional-coordinator-feedback>
    </section>

    @if (formErrors.length) {
      @for (error of formErrors; track error) {
        <div class="fs-4 text-danger">*{{ error }}</div>
      }
    }

    <div class="main-btn-group d-flex">
      <a
        class="btn-regular-blue dark"
        routerLink="."
        [queryParams]="{ mode: 'view' }"
        target="_blank"
        type="button"
        >Preview</a
      >
      <button class="btn-regular-blue dark" type="submit">Send to Chair</button>
    </div>
  </form>
</ng-template>

<ng-template #documentModel let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Upload document</h1>
    <button
      mat-icon-button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      (click)="close()"
    ></button>
  </div>
  <form [formGroup]="documentForm">
    <div class="modal-body newinputs newformmodal">
      <mat-form-field appearance="fill" class="">
        <mat-label>Document Description</mat-label>
        <input matInput type="text" id="description" formControlName="description" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="">
        <mat-label>File</mat-label>
        <input matInput type="file" id="documnetFile" (change)="persistS3Image($event, 'doc')" />
        @if (isUploading) {
          <div>Uploading...</div>
        }
      </mat-form-field>
    </div>
  </form>

  <div class="modal-footer">
    <button class="secondbtn" (click)="close()">Cancel</button>
    <button class="createbtn" [disabled]="!documentForm.valid" (click)="uploadDocument()">
      Save
    </button>
  </div>
</ng-template>

<ng-template #prevProject>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>Add new project</h1>
    <button mat-dialog-close class="fs-6 me-4">
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <form [formGroup]="regionalCoordinatorForm.controls.rcProject">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Title</mat-label>
            <input matInput type="text" formControlName="title" />
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Objectives</mat-label>
            <textarea cdkTextareaAutosize matInput formControlName="objectives" rows="2"></textarea>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Strategic Alignment</mat-label>
            <textarea
              cdkTextareaAutosize
              matInput
              formControlName="strategicAlignment"
              rows="2"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Councils Involved</mat-label>
            <input matInput type="text" formControlName="councilsInvolved" />
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Bidpool Cost</mat-label>
            <input matInput type="number" formControlName="bidpoolCost" />
          </mat-form-field>
        </div>

        @if (isPrevious) {
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>%Complete</mat-label>
              <input matInput type="number" formControlName="complete" />
            </mat-form-field>
          </div>
        }
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="ps-4 mb-3">
    <button class="secondbtn me-3" mat-dialog-close>Cancel</button>
    <button class="createbtn" (click)="addProject()">Create</button>
  </mat-dialog-actions>
</ng-template>
